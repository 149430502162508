require('./bootstrap');
require('./jquery-migrate-3.0.0');
require('./bootstrap-select');
require('./magnific-popup');
require('./waypoints');
require('./counterup');
require('./waypoints-sticky');
require('./isotope');
require('./owl.carousel');
require('./stellar');
require('./theia-sticky-sidebar');
require('./general');
require('./custom');
require('./jquery.themepunch.tools');
require('./jquery.themepunch.revolution');
require('./revolution-plugin');
require('./rev-script-3');
require('./whatsapp-widget');
